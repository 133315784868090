$primary: #007bff;
$secondary: #6c757d;
$accent: darken($primary, 20%);

$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "accent":     $accent,
);

$body-starting-bg: #0d0e0f;
$body-bg: #204f6c;
$body-color: #FDFDFD;

$font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Noto Sans", "Ubuntu", "Droid Sans", "Helvetica Neue", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-weight-bold: 700;
$font-weight-medium: 500;

$headings-color: $body-color;

$spacer: 1rem;

$enable-shadows:              true;

$font-size-base:              1rem;
$spacer:                      1rem;

$h1-font-size:                $font-size-base * 1.1;
$h2-font-size:                $font-size-base * 1.1;
$h3-font-size:                $font-size-base * 1.1;
$h4-font-size:                $font-size-base * 1.1;
$h5-font-size:                $font-size-base * 1.0;
$h6-font-size:                $font-size-base * 1.0;

$headings-margin-bottom:      0;
$headings-font-weight:        $font-weight-bold;
$headings-line-height:        1.5;

$column-padding-y: 10px;
$column-padding-x: 10px;

$btn-box-shadow: none;

$headings-font-family: $font-family-sans-serif;

$breadcrumb-divider: '>';

$border-radius:               .3rem !default;
$border-radius-lg:            .5rem !default;
$border-radius-sm:            .25rem !default;

$column-bg-color: #383838;
$column-text-color: $body-color;
$task-bg-color: lighten($column-bg-color, 10%);
//$task-bg-color: #2a4158;
$task-text-color: $column-text-color;

$modal-content-bg:                  $column-bg-color;
