.column-content {
    overflow-y: auto;
    overflow-x: hidden;

    padding-left: $column-padding-y / 2;
    padding-right: $column-padding-y / 2;

    margin-left: -($column-padding-y / 2);
    margin-right: -($column-padding-y / 2);

    min-height: 15px;
}

.column {
    display: flex;
    flex-direction: column;
    
    background-color: $column-bg-color;
    color: $column-text-color;
    border-radius: 2px;

    max-height: 100%;
    
    padding: $column-padding-y $column-padding-x;
    margin: 0px 5px;

    &:first-of-type() {
        margin-left: 0;
    }

    &:last-of-type() {
        margin-right: 0;
    }

    transition: background-color 0.2s ease 0s, opacity 0.1s ease 0s;
    
    user-select: none;
    width: 270px;
    flex-shrink: 0;
}

.column-button {
    cursor: pointer;

    padding: $column-padding-x;
    border-radius: 2px;

    &:hover {
        background-color:darken($column-bg-color, 5%) ;
    }

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.column-title {
    cursor: grab;

    padding: 0px $column-padding-x $column-padding-y;
}

.column-title-input {
    padding-bottom: $column-padding-y;
    margin-top: -2px;
    margin-left: -1px;

    input {
        min-width: 0;
        width: 100%;
        font-size: $h1-font-size;
        font-weight: $font-weight-bold;

        border-radius: $input-border-radius;

        padding: 0 $column-padding-x;
        line-height: 1;

        border: 1px solid $gray-600;
    }

    .icon-btn {
        font-size: 1.9 * $font-size-base;
    }
}