.workspace-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    
    position: absolute;
    top: 40px;
    left: 0;
    right: 0;
    bottom: 0;
}

.workspace-navbar {
    background-color: $body-bg;
    position: fixed;

    padding: 0 $column-padding-y;

    top: 0;
    left: 0;
    width: 100vw;

    height: 40px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    .icon-btn {
        font-size: 1.8 * $font-size-base;
    }
}

.workspace {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    
    padding: $column-padding-y;

    flex: 1;

    position: absolute;
    top: 0;
    bottom: 0;
}