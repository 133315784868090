@charset "UTF-8";

@import url('https://fonts.googleapis.com/css?family=Fira+Sans:400,500,700&display=swap&subset=latin-ext');

@import './scss/variables.scss';
@import './bootstrap/bootstrap.scss';

@import './scss/components/workspace.scss';
@import './scss/components/column.scss';
@import './scss/components/task.scss';
@import './scss/components/workspace-manager.scss';

@import './scss/main.scss';
