.task {
    display: flex;
    flex-direction: column;

    color: rgb(9, 30, 66);
    border-radius: 2px;
    border: 2px solid transparent;
    background-color: $task-bg-color;
    color: $task-text-color;
    box-shadow: none;
    box-sizing: border-box;

    cursor: grab;
    user-select: none;

    min-height: 40px;
    margin-bottom: $column-padding-x;
    padding: $column-padding-x $column-padding-x;

    p {
        margin: 0;
    }
}

textarea.task, textarea.task-description {
    min-width: 0;
    width: 100%;

    border-radius: $input-border-radius;
    outline: none;

    padding: $column-padding-x;
    line-height: 1.5;

    border: 1px solid $gray-600;

    cursor: initial;
    resize: none;
}