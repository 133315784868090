$transparency_values: (
    "10": .1,
    "20": .2,
    "30": .3,
    "40": .4,
    "50": .5,
    "60": .6,
    "70": .7,
    "80": .8,
    "90": .9
);

@each $name, $value in $transparency_values {
    .bg-black-t#{$name} {
        background-color: rgba(black, $value) !important;
    }
    .bg-white-t#{$name} {
        background-color: rgba(white, $value) !important;
    }
}

.narrow {
    letter-spacing: normal;
}

.lh-1 {
    line-height: 1 !important;
}

.icon-btn {
    cursor: pointer;

    display: flex;

    color: $light;
    @extend a.text-light;

    font-size: 1.2 * $font-size-base;
}

body {
    @include gradient-y($body-starting-bg, $body-bg);

    height: 100vh;
    overflow-y: hidden;
    overflow-x: auto;
}

#root {
    height: 100%;
}

.modal-header .close {
    @extend .text-white;
}

.rdtPicker {
    color:black;
}