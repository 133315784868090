.workspace-manager {
    min-height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .alert {
        max-width: 1000px;
    }
    .workspaces {
        max-width: 1000px;

        display: flex;
        align-items: center;
        justify-content: center;
        
        flex-direction: row;
        flex-wrap: wrap;
    }

    .workspace-button {
        display: flex;
        justify-content: center;
        align-items: center;

        border: 1px solid rgba(255,255,255,0.7);

        width: 200px;
        height: 120px;

        overflow: hidden;

        background-color: rgba(255,255,255,0.2);
        cursor: pointer;
        user-select: none;

        margin: 8px;
        border-radius: $border-radius;

        svg {
            font-size: 60px;
        }

        &:hover {
            background-color: rgba(255,255,255,0.3);
        }
    }
}